import { blog1 } from "./blogs/A-Comprehensive-Guide-to-AutoCAD-for-Beginners-and-Professionals.js";
import { blog2 } from "./blogs/10-Essential-AutoCAD-Commands-Every-Designer-Should-Know.js";
import { blog3 } from "./blogs/Top-10-AutoCAD-Plugins-to-Boost-Your-Design-Workflow.js";
import { blog4 } from "./blogs/7-Expert-Tips-to-Improve-Your-AutoCAD-Design.js";
import { blog5 } from "./blogs/Advanced-AutoCAD-Techniques-for-Design-Professionals.js";
import { blog6 } from "./blogs/The-Ultimate-AutoCAD-Shortcuts-Guide-Speed-Up-Your-Workflow.js";
import { blog7 } from "./blogs/Should-You-Learn-AutoCAD-or-Revit-A-Comprehensive-Comparison.js";
import { blog8 } from "./blogs/What-is-AutoCAD-Used-For-Key-Applications-Across-Industries.js";
import { blog9 } from "./blogs/Is-AutoCAD-Free-or-Paid-Understanding-Licensing.js";
import { blog10 } from "./blogs/Can-AutoCAD-Run-on-a-Mac-Compatibility-Features-and-Alternatives.js";
import { blog11 } from "./blogs/Can-AutoCAD-Be-Used-for-3D-Printing-A-Guide-for-Designers-and-Engineers.js";
import { blog12 } from "./blogs/autocad-trial-vs-full-version.js";
import { blog13 } from "./blogs/autocad-license-for-architects-and-designers.js";
import { blog14 } from "./blogs/AutoCAD-vs-AutoCAD-LT-Which-is-Right-for-You.js";
import { blog15 } from "./blogs/Getting-Started-with-Civil-3D-Key-Features-and-Benefits.js";
import { blog16 } from "./blogs/How-to-Use-AutoCAD-Online-A-Guide-for-Remote-Designers.js";
import { blog17 } from "./blogs/autocad-for-3d-modeling.js";
import { blog18 } from "./blogs/autocad-electrical-key-features.js";
import { blog19 } from "./blogs/Online-CAD-Drawing-Tools-Alternatives-and-When-to-Use-AutoCAD-Web.js";
import { blog20 } from "./blogs/ten-reasons-learn-autocad-civil-engineers.js";
import { blog21 } from "./blogs/autocad-for-beginners-complete-guide-to-start-learning-in-2025.js";
import { blog22 } from "./blogs/is-autocad-worth-learning-in-2025-complete-career-analysis.js";
import { blog23 } from "./blogs/autocad-vs-revit-vs-sketchup-which-one-should-you-choose.js";
import { blog24 } from "./blogs/can-you-run-autocad-on-mac-complete-compatibility-guide.js";
import { blog25 } from "./blogs/autocad-file-management-complete-guide-to-backup-and-recovery.js";
import { blog26 } from "./blogs/autocad-performance-optimization-speed-up-your-workflow.js";
import { blog27 } from "./blogs/autocad-in-civil-engineering-essential-tools-and-workflows.js";
import { blog28 } from "./blogs/autocad-for-electrical-engineering-complete-guide.js";
import { blog29 } from "./blogs/top-15-autocad-problems-and-how-to-fix-them.js";
import { blog30 } from "./blogs/complete-guide-to-using-autocad-on-ipad-and-mobile-devices.js";
import { blog31 } from "./blogs/autocad-network-license-vs-standalone-complete-comparison-guide.js";
import { blog32 } from "./blogs/civil-3d-licensing-guide-subscription-options-and-features.js";
import { blog33 } from "./blogs/autocad-in-manufacturing-essential-tools-and-best-practices.js";
import { blog34 } from "./blogs/autocad-for-interior-design-professional-workflow-guide.js";
import { blog35 } from "./blogs/autocad-network-performance-optimization-guide.js";
import { blog36 } from "./blogs/complete-guide-to-autocad-file-formats-and-compatibility.js";
import { blog37 } from "./blogs/autocad-vs-bricscad-in-depth-comparison-for-professionals.js";

export const blogData = [
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  blog7,
  blog8,
  blog9,
  blog10,
  blog11,
  blog12,
  blog13,
  blog14,
  blog15,
  blog16,
  blog17,
  blog18,
  blog19,
  blog20,
  blog21,
  blog22,
  blog23,
  blog24,
  blog25,
  blog26,
  blog27,
  blog28,
  blog29,
  blog30,
  blog31,
  blog32,
  blog33,
  blog34,
  blog35,
  blog36,
  blog37
]
