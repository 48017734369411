import Head from "next/head";
import Image from "next/image";
import Link from "next/link";


function BlogItem({
  tags,
  title,
  slug,
  shortDescription,
  blogImages
}) {

  const image = blogImages[0].url
  const alt = blogImages[0].description

  return (
   <>
    <Head>
         <link rel="preload" href={image} as="image" />
    </Head>
    <Link
      title={title}
      href={"/blog/" + slug}
      className="flex flex-col gap-1 rounded-2xl border border-solid border-[#b1b1b1]  p-6 font-bold text-black transition hover:[box-shadow:#EC1C1C_7px_7px]"
    >
      <Image
        width={200}
        height={200}
        quality={75}
        priority
        src={image}
        alt={alt}
        // loading="lazy"
        // placeholder="blur"
        className="inline-block !h-60  w-full !object-cover rounded-lg"
      />
      <div className="w-full justify-between h-full  flex flex-col items-start pt-4">
        <div>
          <div className="flex  gap-2">
            {tags.map((e, i) => (
              <h2
                key={i}
                className="bg-[#ec1c1cc7] capitalize text-white rounded-full text-[10px] w-fit px-2 py-0.5 mb-2"
              >
                {e}
              </h2>
            ))}
          </div>
          <p className="mb-4 text-xl font-semibold">{title}</p>
          <p className="mb-5 text-sm font-normal text-[#636262] lg:mb-8">
            {shortDescription}
          </p>
        </div>
        {/* <div className="  flex max-w-[480px] flex-row">
          <Image
            width={100}
            height={100}
            src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63919c4a64bf2667d0775e71_Ellipse%2027.png"
            alt="profile__image"
            class="mr-4 inline-block h-12 w-12 rounded-full object-cover"
          />
          <div className="flex flex-col items-start">
            <h1 className="text-sm font-semibold">{authorName}</h1>
            <div className="flex items-start max-[991px]:flex-col lg:items-center">
              <p className="text-xs text-[#636262]">{date}</p>
              <p className="ml-2 mr-2 text-xs text-[#636262] max-[991px]:hidden">
                -
              </p>
              <p className="text-xs text-[#636262]">{minutes} mins read</p>
            </div>
          </div>
        </div> */}
      </div>
    </Link>
   </>
  );
}

export default BlogItem;
